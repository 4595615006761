.ferienhaus-slider {
  position: relative;
  background-color: var(--grey);
  padding-bottom: var(--spacing-3);
}

.ferienhaus-slider__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
}

.ferienhaus-slider__cell-ratio {
  height: 70vw;
  max-height: calc((var(--max-width) - 60px - (var(--side) * 2)) / 4);
  width: calc(70vw + 20px);
  max-width: calc(((var(--max-width) - 60px - (var(--side) * 2)) / 4) + 20px);
}

.ferienhaus-slider__cell {
  position: relative;
  height: 0;
  padding-top: 100%;
  width: calc(100% - 20px);
}

.ferienhaus-slider__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

@media screen and (min-width: 1340px) {
  .ferienhaus-slider .flickity-prev-next-button.next {
    right: -40px;
    outline: unset;
  }
  .ferienhaus-slider .flickity-prev-next-button.previous {
    outline: unset;
    left: -40px;
  }
}
