.chalet-teaser__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: var(--spacing-1);
  display: grid;
  grid-template-columns: 1.8fr 1.1fr 0.6fr;
  grid-template-rows: 1.9fr 1.2fr 0.4fr auto;
  grid-auto-flow: dense;
}

@media screen and (min-width: 1000px) {
  .chalet-teaser__inner {
    padding: 0 var(--side) var(--spacing-4);
    grid-template-columns: 2.5fr 3.5fr 2.1fr 3.8fr;
    grid-template-rows: 1fr 3.6fr 0.8fr 1fr 0.6fr;
  }
}

.chalet-teaser__text {
  font-size: var(--paragraph-size);
  margin: 1em 0 2em;
  max-width: 1000px;
  letter-spacing: 0.6px;
}

.chalet-teaser__headline {
  font-family: var(--font-copy);
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: 0.9px;
  color: var(--brown);
  max-width: 500px;
}

@media screen and (min-width: 1000px) {
  .chalet-teaser__headline {
    font-size: 30px;
    max-width: unset;
  }
}

.chalet-teaser__text p {
  margin-bottom: 1em;
  line-height: 1.5em;
}

.chalet-teaser__text ul {
  margin: 0;
  padding: 0 0 0 1.2em;
}

.chalet-teaser__text li {
  margin: 0;
  line-height: 1.8;
}

.chalet-teaser__text li p {
  margin: 0;
}

.chalet-teaser__info-inner {
  max-width: var(--max-width-small);
  margin: 0 auto;
}

.chalet-teaser__info {
  grid-column: 1 / -1;
  grid-row: 4 / 5;
  background-color: var(--grey);
  padding: var(--spacing-2) var(--side) var(--spacing-4);
  position: relative;
}

@media screen and (min-width: 1300px) {
  .chalet-teaser__info::after {
    content: "";
    background-image: url("../../images/voegel.svg");
    width: 130px;
    height: 107px;
    background-size: contain;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    right: -70px;
    top: 25%;
  }
}

.chalet-teaser__info::before {
  content: "";
  width: 100%;
  height: 100px;
  background-color: var(--grey);
  position: absolute;
  top: -100px;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 1000px) {
  .chalet-teaser__info::before {
    display: none;
  }

  .chalet-teaser__info {
    grid-column: 3 / -1;
    grid-row: 2 / 5;
    padding: 50px 50px 60px 60px;
  }

  .chalet-teaser__info-inner {
    max-width: unset;
    margin: 0;
  }
}

.chalet-teaser__image-big {
  grid-column: 1 / -2;
  grid-row: 1 / 3;
  position: relative;
  display: flex;
}

@media screen and (min-width: 1000px) {
  .chalet-teaser__image-big {
    grid-column: 1 / -2;
    grid-row: 1 / 4;
  }
}

.chalet-teaser__image-big::after {
  content: "";
  background-image: url("../../images/voegel.svg");
  width: 25vw;
  max-width: 130px;
  height: 25vw;
  max-height: 130px;
  background-size: contain;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  right: -10vw;
  top: 10vw;
}

@media screen and (min-width: 1300px) {
  .chalet-teaser__image-big::after {
    display: none;
  }
}

.chalet-teaser__image-small {
  grid-column: span 2 / -1;
  grid-row: 2 / 4;
  position: relative;
  display: flex;
}

@media screen and (min-width: 1000px) {
  .chalet-teaser__image-small {
    grid-column: 2 / 3;
    grid-row: 3 / 6;
  }
}

.chalet-teaser__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chalet-teaser__button-wrapper {
  max-width: 325px;
}

.chalet-teaser__button {
  color: var(--white);
  background-color: var(--brown);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.chalet-teaser__button:hover,
.chalet-teaser__button:focus {
  background-color: var(--orange);
}
