.slider-gallery {
  background-color: var(--grey);
}

.slider-gallery__inner {
  --slider-gallery--gap: 20px;

  margin: 0 auto;
  padding: 0 var(--side) var(--spacing-4);
  position: relative;
  max-width: 400px;
}

@media screen and (min-width: 768px) {
  .slider-gallery__inner {
    max-width: var(--max-width);
  }
}

@media screen and (min-width: 1340px) {
  .slider-gallery__inner {
    --slider-gallery--gap: 30px;
  }
}

.carousel-cell {
  margin-right: var(--slider-gallery--gap);
}

@media screen and (min-width: 768px) {
  .slider-gallery__image {
    max-width: calc((var(--max-width) - (var(--slider-gallery--gap) * 5)) / 4);
  }
}
