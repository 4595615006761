.navigation {
  box-shadow: 0 0 8px #59453633;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 5;
  position: fixed;
  width: 100%;
  transform: translateY(0);
  transition: transform var(--link-transition);
  height: 100px;
  display: flex;
}

@media screen and (min-width: 1000px) {
  .navigation {
    height: 150px;
  }
}

.navigation--hidden {
  transform: translateY(-100%);
}

.navigation__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navigation__logo {
  max-width: 150px;
}

@media screen and (min-width: 1000px) {
  .navigation__logo {
    max-width: 200px;
  }
}

.navigation__main-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 1100px) {
  .navigation__main-links {
    flex-direction: row;
    margin: 0 70px 0 0;
  }
}

.navigation__main-link {
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 2.85px;
  color: var(--brown);
  transition: color var(--link-transition);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 19px;
  margin: 0 0 1.25em;
  display: inline-block;
  position: relative;
  text-align: center;
  padding-left: 2px;
}

@media screen and (min-width: 1100px) {
  .navigation__main-link {
    margin: 0 0 0 70px;
  }
}

@media screen and (min-width: 1100px) {
  .navigation__main-link--home {
    display: none;
  }
}

.navigation__main-link:hover {
  color: var(--orange);
}

.navigation__main-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 var(--spacing-2);
  z-index: 11;
  background-color: white;
}

.navigation__main-wrapper--visible {
  transition: transform 250ms ease;
  transform: translateX(0);
}

.navigation__main-wrapper--hidden {
  transition: transform 250ms ease;
  transform: translateX(-100vw);
}

@media screen and (min-width: 1100px) {
  .navigation__main-wrapper {
    flex-direction: row;
    justify-content: space-between;
    position: static;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    width: 100%;
    height: unset;
    transform: unset;
    padding: 0;
    background-color: unset;
  }
}

.navigation__main-links-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-6);
}

@media screen and (min-width: 1100px) {
  .navigation__main-links-wrapper {
    background-color: unset;
    height: unset;
    padding-top: 0;
  }
}

.navigation__burger {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: unset;
}

@media screen and (min-width: 1100px) {
  .navigation__burger {
    display: none;
  }
}

.navigation__social-nav {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: var(--spacing-5);
}

@media screen and (min-width: 1100px) {
  .navigation__social-nav {
    background-color: unset;
    height: unset;
    display: block;
    justify-content: unset;
    align-items: unset;
    padding-bottom: 0;
  }
}

.navigation__burger-close {
  position: absolute;
  top: 100px;
  right: var(--side);
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: unset;
}

@media screen and (min-width: 1100px) {
  .navigation__burger-close {
    display: none;
  }
}

.navigation__main-link--active {
  color: var(--orange);
}

@media screen and (min-width: 1100px) {
  .navigation__main-link--active {
    color: var(--brown);
  }

  .navigation__main-link--active::after {
    content: " ";
    width: 100%;
    background-color: var(--orange);
    height: 2px;
    position: absolute;
    bottom: -0.25em;
    left: 0;
  }
}
