.travels-filter {
  --travels-filter-color: #5945367c;

  background-color: var(--grey);
  position: relative;
}

.travels-filter__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-5) var(--side-small) 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .travels-filter__inner {
    display: grid;
    grid-template-columns: repeat(3, minmax(1px, 1fr));
    column-gap: var(--travels-gap);
    align-items: start;
  }
}

.travels-filter__inner::before {
  content: "";
  background-image: url("../../images/kaktus-grey.svg");
  width: 12vw;
  height: 12vw;
  max-width: 100px;
  max-height: 100px;
  position: absolute;
  top: -11.8vw;
  right: 12vw;
  background-repeat: no-repeat;
  object-fit: contain;
  object-position: bottom;
  z-index: -1;
}

@media screen and (min-width: 833px) {
  .travels-filter__inner::before {
    right: 100px;
    top: -99px;
  }
}

.travels-filter__group--destination {
  display: none;
}

.travels-filter__group--destination--visible {
  display: block;
}

.travels-filter__group {
  width: 100%;
  position: relative;
}

.travels-filter__nav {
  padding: 7px 0;
  margin: 0;
  list-style: none;
  max-height: 250px;
  overflow: scroll;
}

.travels-filter__button {
  all: unset;
  padding: 7px 16px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  color: white;
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .travels-filter__button {
    padding: 7px 20px;
  }
}

.travels-filter__button:hover {
  color: var(--travels-filter-color);
}

.travels-filter__button--active {
  font-weight: bold;
  color: var(--travels-filter-color);
}

.travels-filter__header {
  list-style: none;
  padding: 8px 0;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--travels-filter-color);
  border-bottom: 2px solid var(--travels-filter-color);
  margin: 0 16px;
  letter-spacing: 0.01em;
}

@media screen and (min-width: 1000px) {
  .travels-filter__header {
    margin: 0 20px;
  }
}

.travels-filter__header::-webkit-details-marker {
  display: none;
}

/* Hide arrow icon in IE browsers */
.travels-filter__header::-ms-expand {
  display: none;
}

.travels-filter__header:focus {
  outline: none;
}

.travels-filter__header::after {
  content: "";
  background-image: url("../../images/filter-arrow.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  object-fit: contain;
  object-position: center;
  transition: transform var(--link-transition);
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 3;
}

.travels-filter__group[open] .travels-filter__header::after {
  transform: rotate(90deg);
}

.travels-filter__nav-wrapper {
  position: absolute;
  width: 100%;
  background-color: rgba(221, 218, 215, 0.9);
  z-index: 2;
}

.travels-filter__selected {
  display: flex;
  gap: var(--travels-gap);
  margin: 0 16px var(--travels-gap);
}

@media screen and (min-width: 1000px) {
  .travels-filter__selected {
    margin: 0 20px var(--travels-gap);
  }
}

.travels-filter__selected-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
}

.travels-filter__group--disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.travels-filter__dates-button {
  color: var(--white);
  background-color: var(--brown);
  padding: 0.75em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
  margin-top: var(--spacing-1);
}

.travels-filter__dates-button:hover,
.travels-filter__dates-button:focus {
  background-color: var(--orange);
}

.travels-filter__dates-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.travels-filter__dates-button:disabled:hover {
  background-color: var(--brown);
}

.travels-filter__dates-clear-button {
  color: var(--brown);
  background-color: unset;
  padding: 0.75em;
  font-family: var(--font-copy);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.54px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: color var(--link-transition);
}

.travels-filter__dates-clear-button:hover,
.travels-filter__dates-clear-button:focus {
  color: var(--orange);
}

.travels-filter__dates-clear-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.travels-filter__dates-clear-button:disabled:hover {
  color: var(--brown);
}

.travels-filter__dates-wrapper {
  padding: 14px 16px;
  position: absolute;
  width: 100%;
  background-color: rgba(221, 218, 215, 0.9);
  z-index: 3;
  color: white;
}

@media screen and (min-width: 1000px) {
  .travels-filter__dates-wrapper {
    padding: 14px 20px;
  }
}

.travels-filter__date-input {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.travels-filter__start-date-input,
.travels-filter__end-date-input {
  color: var(--dark-grey);
  line-height: var(--line-height);
  font-size: 16px;
  font-family: var(--font-copy);
  border: none;
  border-radius: 0;
  padding: 4px 8px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.8);
}

.no-results {
  grid-column: 1 / -1;
  text-align: center;
}
