.footer {
  padding-top: 150px;
  overflow: hidden;
}

.footer__top {
  position: relative;
  background-color: var(--brown);
  color: var(--white);
  width: 100%;
}

.footer__top::before {
  content: "";
  position: absolute;
  height: 50%;
  top: calc(3px - 50%);
  left: 0;
  background-image: url(../../images/footer.svg);
  background-position: bottom 0 left 70%;
  background-repeat: no-repeat;
  background-size: 900px;
  width: 100%;
}

@media screen and (min-width: 750px) {
  .footer__top::before {
    top: calc(3px - 100%);
    height: 100%;
    background-size: var(--max-width);
    max-width: var(--max-width);
    background-position: bottom;
  }
}

@media screen and (min-width: 1200px) {
  .footer__top::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.footer__bottom {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-1) var(--side) var(--spacing-2);
  color: #594536;
  font-size: 14px;
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 750px) {
  .footer__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__rights {
  text-align: center;
  line-height: 2em;
  opacity: 0.7;
}

.footer__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-4) var(--side);
}

.footer__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 1000px) {
  .footer__nav {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

.footer__logo {
  max-width: 160px;
}

.footer__main-links {
  list-style: none;
  padding: 0;
  text-align: center;
  margin: var(--spacing-2) 0;
}

@media screen and (min-width: 1000px) {
  .footer__main-links {
    display: flex;
    list-style: none;
    margin: 0 70px 0 0;
    padding: 0;
    align-items: center;
  }
}

.footer__main-link {
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 2.85px;
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  line-height: 2.2;
  display: inline-block;
  transition: color var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .footer__main-link {
    margin-left: 70px;
  }
}

.footer__main-link:hover {
  color: var(--orange);
}

.footer__contact-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.footer__contact-link {
  background-color: var(--white);
  width: 40px;
  height: 40px;
  color: var(--brown);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 28px;
}

.footer__contact-link:first-child {
  margin-left: 0;
}

.footer__sub-nav {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2em;
}

@media screen and (min-width: 750px) {
  .footer__sub-nav {
    display: flex;
  }
}

.footer__sub-nav-link {
  text-decoration: none;
  opacity: 0.7;
  transition: opacity var(--link-transition);
}

@media screen and (min-width: 750px) {
  .footer__sub-nav-link {
    display: flex;
    margin-left: 2em;
  }
}

.footer__sub-nav-link:hover {
  text-decoration: underline;
  opacity: 1;
}

.footer__link-since {
  text-decoration: none;
}
