.flickity-button-icon {
  fill: var(--brown);
}

@media screen and (min-width: 1340px) {
  .flickity-button {
    background-color: unset;
  }

  .flickity-button:hover {
    background-color: unset;
  }

  .flickity-prev-next-button {
    width: 70px;
    height: 70px;
  }
}
