.card {
  --card-shift: 20px;

  background-color: var(--white);
  padding: 0 16px 26px 16px;
  margin-top: calc(2 * var(--card-shift));
  margin-bottom: var(--spacing-3);
  text-decoration: none;
  transition: box-shadow var(--link-transition);
  display: block;
}

.card:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .card {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1000px) {
  .card {
    /* margin-bottom: var(--spacing-4); */
    padding: 0 20px 30px 20px;
  }
}

.card--grey {
  background-color: var(--grey);
}

.card__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card__headline {
  font-size: 18px;
  margin: 0 0 1.5em;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.card__headline-kicker {
  margin: 0 0 0.5em;
  font-weight: 700;
}

.card__text {
  font-size: 16px;
  margin-bottom: 2em;
  letter-spacing: 0.01em;
}

.card__cover-wrapper {
  position: relative;
  transform: translateY(calc(0px - var(--card-shift)));
  padding-top: 59%;
  height: 0;
  display: flex;
  justify-content: center;
}

.card__cover {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: calc(100% + var(--card-shift));
  object-fit: cover;
}

.card__label {
  min-width: 60%;
  background-color: #e18700e6;
  text-align: center;
  padding: 8px;
  position: absolute;
  color: var(--white);
  top: calc(0px - var(--card-shift));
  display: inline-block;
}

.card__facts {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
}

.card__fact {
  font-family: var(--font-copy);
  font-weight: 700;
}

.card__fact--addition {
  font-family: var(--font-copy);
  font-weight: 400;
  margin-left: 2px;
}

.card__button {
  color: var(--white);
  background-color: var(--brown-light);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.card__button:hover,
.card__button:focus {
  background-color: var(--orange);
}

.card:hover {
  box-shadow: 0 0 15px #59453680;
}

.card:hover .card__button {
  background-color: var(--orange);
}
