/* html {
  scroll-behavior: smooth;
} */

html,
body {
  padding: 0;
  margin: 0;
  color: var(--dark-grey);
  font-family: var(--font-copy);
  line-height: var(--line-height);
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
  font-weight: 400;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

p {
  letter-spacing: 0.01em;
}

.globals__main-content {
  padding-top: 100px;
}

@media screen and (min-width: 1000px) {
  .globals__main-content {
    padding-top: 150px;
  }
}

button {
  cursor: pointer;
}
