.travel-card-related {
  --travel-card-shift: 30px;

  background-color: var(--grey);
  padding: 0 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: calc(2 * var(--travel-card-shift));
  margin-bottom: var(--spacing-4);
  box-shadow: 0 0 0 #59453600;
  transition: box-shadow var(--link-transition);
  position: relative;
  text-decoration: none;
}

.travel-card-related:hover {
  box-shadow: 0 0 15px #59453680;
}

.travel-card-related:hover .link-cta {
  background-color: var(--orange);
}

.travel-card-related__headline {
  font-size: 18px;
  margin: 0 0 0.5em;
  position: relative;
  font-weight: 700;
}

.travel-card-related__text {
  font-size: 16px;
  margin-bottom: 2em;
}

.travel-card-related__cover-wrapper {
  position: relative;
  transform: translateY(calc(0px - var(--travel-card-shift)));
  padding-top: 59%;
  height: 0;
}

.travel-card-related__cover {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: calc(100% + var(--travel-card-shift));
  object-fit: cover;
}

.travel-card-related__facts {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
  font-family: var(--font-copy);
  font-size: 14px;
  font-weight: 700;
}

.travel-card-related__fact--addition {
  font-family: var(--font-copy);
  font-weight: 400;
}
