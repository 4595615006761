.address {
  margin: var(--spacing-2) 0;
}

@media screen and (min-width: 1000px) {
  .address {
    margin: var(--spacing-4) 0 var(--spacing-7);
  }
}

.address__inner {
  max-width: 580px;
  margin: 0 auto;
  padding: 0 var(--side);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .address__inner {
    display: grid;
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    gap: 40px;
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .address__inner {
    max-width: var(--max-width-med);
    grid-template-columns: repeat(3, minmax(1px, 1fr));
  }
}

.address__headline {
  font-family: var(--font-headline);
  font-size: 30px;
  line-height: 1.75;
  color: var(--brown);
  margin-top: var(--spacing-2);
}

.address__address {
  font-size: var(--paragraph-size);
  line-height: 1.75;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}

.address__opening-times {
  font-size: var(--paragraph-size);
  line-height: 1.75;
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 400;
}

.address__address-link {
  text-decoration: none !important;
  font-weight: normal;
}

.address__address-link--mail {
  text-decoration: underline;
}

.address__opening-times-item {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .address__opening-times-item {
    justify-content: space-between;
    max-width: 230px;
  }
}

@media screen and (min-width: 1000px) {
  .address__opening-times-item {
    max-width: 260px;
  }
}

.address__address-phone {
  margin-top: var(--spacing-2);
  font-weight: normal;
}

@media screen and (min-width: 1000px) {
  .address__address-phone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    margin: 0 0 0 10px;
    height: 128px;
  }
}

@media screen and (min-width: 1200px) {
  .address__address-phone {
    height: 140px;
  }
}

.address__day {
  margin-right: 0.3em;
}

.address__lageplan-wrapper {
  max-height: 700px;
  margin-top: var(--spacing-2);
}

.address__lageplan {
  width: 100%;
  object-fit: contain;
  object-position: center;
  grid-column: 1/-1;
  display: inline-block;
}

.address__times {
  white-space: nowrap;
}

.address__lageplan-details {
  grid-column: 1/-1;
  margin-top: var(--spacing-1);
}

.address__lageplan-title {
  text-decoration: underline;
  font-size: var(--paragraph-size);
  list-style: none;
  transition: color var(--link-transition);
  display: inline;
  cursor: pointer;
  font-weight: 400;
  outline: unset;
}

.address__lageplan-title-icon {
  width: 1.5em;
  height: 1.5em;
  object-fit: contain;
  opacity: 1;
  position: relative;
  bottom: -0.3em;
  margin-right: 0.5em;
}

.address__lageplan-title::-webkit-details-marker {
  display: none;
}

/* Hide arrow icon in IE browsers */
.address__lageplan-title::-ms-expand {
  display: none;
}

.address__lageplan-title:hover {
  color: var(--orange);
}
