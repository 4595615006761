.testimonials__inner {
  margin: 0 auto;
  padding: var(--spacing-4) 0 0;
}

@media screen and (min-width: 1340px) {
  .testimonials__inner {
    max-width: var(--max-width);
    padding: var(--spacing-6) var(--side) 0;
  }
}

.testimonials__cards {
  margin: var(--spacing-2) 0;
}

@media screen and (min-width: 600px) {
  .testimonials__cards .flickity-prev-next-button.previous {
    left: calc((100vw - 580px) / 2);
  }

  .testimonials__cards .flickity-prev-next-button.next {
    right: calc((100vw - 580px) / 2);
  }
}

@media screen and (min-width: 1340px) {
  .testimonials__cards .flickity-prev-next-button.previous {
    left: calc(0px - (var(--side) + 44px));
  }

  .testimonials__cards .flickity-prev-next-button.next {
    right: calc(0px - (var(--side) + 44px));
  }
}

.testimonials__card {
  text-align: center;
  background-color: var(--grey);
  width: 100vw;
  margin-top: 75px;
  display: block;
  height: 516px;
}

@media screen and (min-width: 400px) {
  .testimonials__card {
    height: 493px;
  }
}

@media screen and (min-width: 500px) {
  .testimonials__card {
    height: 423px;
  }
}

@media screen and (min-width: 1340px) {
  .testimonials__card {
    width: calc((var(--max-width) - var(--side) - var(--side) - 60px) / 3);
    margin-top: 0;
    margin-right: 30px;
    height: 516px;
  }
}

.testimonials__card-inner {
  padding: 64px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

@media screen and (min-width: 1340px) {
  .testimonials__card-inner {
    padding: 40px 24px;
  }
}

.testimonials__card-headline {
  font-family: var(--font-headline);
  font-size: 30px;
  margin: 1em 0 0.5em;
}

.testimonials__card-text {
  font-size: 16px;
  margin-bottom: 2em;
  line-height: 1.6em;
}

.testimonials__card-sender {
  font-size: 16px;
  font-family: var(--font-copy);
  font-weight: 700;
}

.testimonials__card-person {
  width: 134px;
  background-color: white;
  border-radius: 100%;
  object-fit: scale-down;
  position: absolute;
  top: -70px;
  left: calc(50% - 70px);
}

@media screen and (min-width: 1340px) {
  .testimonials__card-person {
    width: 140px;
    position: static;
    top: unset;
    left: unset;
  }
}

.testimonials__card-star {
  width: 16px;
  margin: 2em 0 1em;
}

.testimonials__headline-wrapper {
  padding: 0 var(--side);
}

@media screen and (min-width: 1340px) {
  .testimonial__slider-no-silder-desktop .flickity-prev-next-button.previous,
  .testimonial__slider-no-silder-desktop .flickity-prev-next-button.next,
  .testimonial__slider-no-silder-desktop .flickity-page-dots {
    display: none;
  }

  .testimonial__slider-no-silder-desktop
    .flickity-enabled.is-draggable
    .flickity-viewport {
    cursor: default !important;
  }
}
