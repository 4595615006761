.travel-images {
  margin: var(--spacing-2) 0;
  position: relative;
}

.travel-images--house {
  display: none;
}

@media screen and (min-width: 768px) {
  .travel-images--house {
    display: block;
  }
}

.travel-images--house {
  margin: var(--spacing-2) 0 0;
}

.travel-images--house::after {
  content: "";
  width: 100%;
  height: 73.25%;
  background-color: var(--grey);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.travel-images--travel {
  display: none;
}

@media screen and (min-width: 768px) {
  .travel-images--travel {
    display: block;
  }
}

.travel-images--travel {
  margin: var(--spacing-3) 0 0;
}

.travel-images--travel::after {
  content: "";
  width: 100%;
  height: 27%;
  background-color: var(--grey);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.travel-images__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  grid-auto-flow: dense;
  position: relative;
  z-index: 1;
}

.travel-images__image-wrapper {
  grid-row: span 1;
  display: flex;
  position: relative;
  height: 0;
  padding-top: 67%;
}

.travel-images__image-wrapper--small {
  padding-top: calc(67% - 10px);
}

.travel-images__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.travel-images__images-small {
  display: grid;
  grid-template-rows: repeat(2, minmax(1px, 1fr));
  gap: 20px;
}

.travel-images__credits {
  position: relative;
  z-index: 1;
  font-size: 12px;
  max-width: var(--max-width);
  margin: 0.5em auto 0;
  padding: 0 var(--side);
  opacity: 0.5;
}

.travel-images__image-big {
  display: flex;
}
