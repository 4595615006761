.link-cta-secondary {
  display: flex;
  justify-content: center;
  margin: var(--spacing-2) 0 0;
}

.link-cta-secondary__link {
  color: var(--brown);
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
}
