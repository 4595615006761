@font-face {
  font-family: "Sketchnote";
  src: url("../fonts/Sketchnote_Square/SketchnoteSquare.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Slab";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto_Slab/RobotoSlab-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Slab";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto_Slab/RobotoSlab-Regular.ttf") format("truetype");
}

:root {
  --font-headline: "Sketchnote";
  --font-copy: "Roboto Slab";
}
