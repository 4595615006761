.travel-highlights {
  background-color: var(--grey);
  position: relative;
}

.travel-highlights__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-4) var(--side-small) 0;
  position: relative;
}

.travel-highlights__cards {
  display: grid;
  margin: var(--spacing-2) 0 var(--spacing-2);
}

@media screen and (min-width: 750px) {
  .travel-highlights__cards {
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    column-gap: 30px;
    row-gap: var(--spacing-4);
    margin: var(--spacing-4) 0;
  }
}

@media screen and (min-width: 1000px) {
  .travel-highlights__cards {
    grid-template-columns: repeat(3, minmax(1px, 1fr));
  }
}

.travel-highlights__cards .card:nth-child(4) {
  margin-bottom: 0;
}

.travel-highlights__cards .card:nth-child(5),
.travel-highlights__cards .card:nth-child(6) {
  display: none;
}

@media screen and (min-width: 750px) {
  .travel-highlights__cards .card:nth-child(4) {
    margin-bottom: var(--spacing-4);
  }
}

@media screen and (min-width: 1000px) {
  .travel-highlights__cards .card:nth-child(5),
  .travel-highlights__cards .card:nth-child(6) {
    display: unset;
  }
}

.travel-highlights__kicker {
  font-size: 24px;
  font-family: var(--font-headline);
  color: var(--brown);
}

.travel-highlights__cards--related {
  grid-template-columns: repeat(4, minmax(1px, 1fr));
  margin: var(--spacing-2) 0;
}

.travel-highlights__link-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-1);
}

.travel-highlights__link {
  color: var(--brown);
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  transition: color var(--link-transition);
}

.travel-highlights__link:hover {
  color: var(--orange);
}
