.contact-form {
  padding: var(--spacing-4) 0 0;
  background-color: var(--grey);
  color: var(--brown);
  position: relative;
}

@media screen and (min-width: 1000px) {
  .contact-form {
    padding: var(--spacing-4) 0 0;
  }
}

.contact-form__inner {
  max-width: var(--max-width-med);
  margin: 0 auto;
  padding: 0 var(--side);
  position: relative;
}

.contact-form__honeypot {
  position: absolute;
  left: -9999px;
}

.contact-form__field {
  margin: 16px 0 4px;
  width: 100%;
}

.contact-form__input {
  border: 2px solid white;
  border-radius: 0;
  color: var(--dark-grey);
  line-height: var(--line-height);
  font-size: 16px;
  font-family: var(--font-copy);
  width: 100%;
  padding: 12px 15px;
}

.contact-form__input:focus {
  outline: unset;
  border: 2px solid #b2dbf0;
}

.contact-form__input::placeholder {
  color: var(--dark-grey-40);
  line-height: var(--line-height);
  font-size: 16px;
  font-family: var(--font-copy);
}

.contact-form__submit-button {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: var(--font-copy);
  background-color: var(--brown-light);
  padding: 1em;
  color: var(--white);
  font-weight: bold;
  line-height: var(--line-height);
  border: unset;
  border-radius: 0;
  transition: background-color var(--link-transition);
  cursor: pointer;
}

.contact-form__submit-button:hover {
  background-color: var(--orange);
}

@media screen and (min-width: 768px) {
  .contact-form__field-wrapper-name {
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }
}

.contact-form__field-wrapper-phone {
  display: flex;
  gap: 8px;
}

.contact-form__field--vorwahl {
  width: 65px;
}

.contact-form__required {
  font-size: 12px;
  color: var(--dark-grey);
  display: inline-block;
  margin: 8px 0;
}

.contact-form__declaration {
  font-size: 14px;
  color: var(--dark-grey);
  margin: 16px 0;
  padding: 0;
  display: block;
  max-width: 700px;
}

.contact-form__declaration a {
  color: inherit;
}

.contact-form__form {
  margin: var(--spacing-2) 0 0;
}

.contact-form__bookingnumber-field,
.contact-form__traveltimes-field {
  display: none;
}

.contact-form__error {
  margin-top: 4px;
  color: #ff4949;
  font-size: 0.87055056rem;
  line-height: 1rem;
  display: inline-block;
}

.contact-form__submit-message {
  margin: 16px 0;
  width: 100%;
  padding: 1em;
  border: 1px solid;
  display: flex;
  gap: 1em;
}

.contact-form__submit-message--error {
  border-color: #ff4949;
  color: #ff4949;
}

.contact-form__submit-message--success {
  border-color: rgb(30 195 30);
  color: rgb(30 195 30);
}

.contact-form__submit-message-text {
  max-width: 650px;
}

.contact-form__icon {
  display: inline-block;
  fill: currentColor;
  width: 26px;
  flex-shrink: 0;
}
