.link-cta {
  color: var(--white);
  padding: 0 1em;
  height: 62px;
  text-decoration: none;
  transition: background-color var(--link-transition);
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 10px);
  background-image: url("../../../assets/images/button-neg-grey.svg");
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: left center;
  position: relative;
  background-color: var(--brown);
  left: -5px;
  border: none;
  box-shadow: unset;
}

.link-cta::before {
  content: "";
  background-image: url("../../../assets/images/button-neg-grey.svg");
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: right;
  position: absolute;
  width: 10px;
  height: 100%;
  right: -2px;
}

.link-cta::after {
  content: "";
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border: 2px solid var(--grey);
  position: absolute;
  left: 0;
  top: 0;
}

.link-cta--white-bg::after {
  border: 2px solid white;
}

.link-cta--white-bg,
.link-cta--white-bg::before {
  background-image: url("../../../assets/images/button-neg-white.svg");
}

.link-cta:hover,
.link-cta:focus {
  background-color: var(--orange);
}
