.compass-img {
  position: absolute;
  width: 75vw;
  max-width: 460px;
  height: 91.3vw;
  max-height: 560px;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
}

.compass-img__img {
  width: 105vw;
  height: 105vw;
  max-width: 644px;
  max-height: 644px;
  position: absolute;
  bottom: 0;
  left: 0;
}
