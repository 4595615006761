.travel__title {
  max-width: var(--max-width);
  margin: var(--spacing-2) auto;
  padding: 0 var(--side);
}

.travel__contact-button {
  background-color: var(--grey);
  position: relative;
}

.travel__link-wrapper {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-2) var(--side);
  position: relative;
}

@media screen and (min-width: 768px) {
  .travel__link-wrapper {
    padding-bottom: 0;
  }
}

.travel__link {
  color: var(--white);
  background-color: var(--brown-light);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 325px;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.travel__link:hover,
.travel__link:focus {
  background-color: var(--orange);
}
