.facts {
  color: var(--brown);
}

@media screen and (min-width: 1340px) {
  .facts {
    background-color: var(--grey);
  }
}

.facts__inner {
  margin: 0 auto;
  padding: var(--spacing-4) 0 0;
}

.facts__inner--house {
  padding: 0;
}

@media screen and (min-width: 1340px) {
  .facts__inner {
    max-width: var(--max-width);

    padding: var(--spacing-4) var(--side);
  }

  .facts__inner--house {
    padding: 0 var(--side);
  }
}

.facts__facts {
  margin: var(--spacing-2) 0;
}

.facts__facts--house {
  margin: 0;
}

.facts__facts::-webkit-scrollbar {
  display: none !important;
  width: 0;
  height: 0;
}

@media screen and (min-width: 1340px) {
  .facts__facts {
    margin: var(--spacing-4) 0 var(--spacing-2);
  }

  .facts__facts--house {
    margin: 0;
  }
}

.facts__fact-headline {
  font-family: var(--font-headline);
  font-size: 30px;
  margin: 1em 0 0.5em;
}

.facts__fact-headline--house {
  text-decoration: underline;
  margin: 0 0 0.5em;
}

.facts__fact-text {
  font-size: var(--paragraph-size);
}

.facts__fact-icon {
  width: 140px;
}

.facts__fact {
  text-align: center;
  background-color: var(--grey-60);
  width: 100vw;
}

@media screen and (min-width: 1340px) {
  .facts__fact {
    margin-right: 30px;
    width: calc((var(--max-width) - var(--side) - var(--side) - 60px) / 3);
  }
}

.facts__card-inner {
  padding: 64px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.facts__card-inner--house {
  padding: var(--spacing-4) 80px;
}

@media screen and (min-width: 1340px) {
  .facts__card-inner {
    padding: 40px 24px;
    height: 100%;
  }

  .facts__card-inner--house {
    padding: var(--spacing-4) 24px;
  }
}

.facts__headline-wrapper {
  padding: 0 var(--side);
}

@media screen and (min-width: 600px) {
  .facts__facts .flickity-prev-next-button.previous {
    left: calc((100vw - 580px) / 2);
  }

  .facts__facts .flickity-prev-next-button.next {
    right: calc((100vw - 580px) / 2);
  }
}

@media screen and (min-width: 1340px) {
  .facts__facts .flickity-prev-next-button.previous {
    left: calc(0px - (var(--side) + 44px));
  }

  .facts__facts .flickity-prev-next-button.next {
    right: calc(0px - (var(--side) + 44px));
  }
}
