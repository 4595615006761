.travels {
  --travels-gap: 30px;
  --filter-color: rgba(89, 69, 54, 0.5);

  background-color: var(--grey);
  position: relative;
}

.travels__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-2) var(--side-small) 0;
  position: relative;
}

.travels__cards {
  display: grid;
  margin: var(--spacing-2) 0 0;
  padding-bottom: var(--spacing-1);
}

@media screen and (min-width: 750px) {
  .travels__cards {
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    column-gap: var(--travels-gap);
    row-gap: var(--spacing-4);
    margin: var(--spacing-4) 0 0;
  }
}

@media screen and (min-width: 1000px) {
  .travels__cards {
    grid-template-columns: repeat(3, minmax(1px, 1fr));
  }
}

.travels__selected-header {
  font-weight: bold;
  font-size: 14px;
  padding: calc(0.5em + 1px) calc(0.5em + 1px) calc(0.5em + 1px) 0;
}

.travels__selected-filter-button {
  all: unset;
  font-size: 14px;
  padding: 0.5em;
  border: 1px solid;
  display: inline-block;
  cursor: pointer;
}

.travels__selected-filter-button::before {
  content: "×";
  color: var(--orange);
}

.travels__selected-filter-button:hover,
.travels__selected-filter-button:focus {
  background-color: var(--orange);
  color: white;
}

.travels__selected-filter-button:focus::before,
.travels__selected-filter-button:hover::before {
  color: white;
}

.travels__more-button {
  display: flex;
  justify-content: center;
  padding: var(--spacing-2) 0;
  grid-column: 1 / -1;
}

@media screen and (min-width: 700px) {
  .travels__more-button {
    padding-bottom: 0;
  }
}

.travels__more-button-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--brown);
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  transition: color var(--link-transition);
  opacity: 1;
}

.travels__more-button-button:hover {
  color: var(--orange);
}

.travels__more-button-button:disabled {
  opacity: 0;
  cursor: none;
}

.travels__pagination-info {
  text-align: center;
  font-size: 14px;
  font-family: var(--font-headline);
}

.travels__pagination-links {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-1);
}

.travels__pagination-link {
  margin: 0 1em;
  text-decoration: none;
  font-weight: bold;
}

.travels__no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1/-1;
}
