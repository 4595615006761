.newsletter {
  margin: var(--spacing-2) 0 var(--spacing-4);
  color: var(--brown);
  position: relative;
}

@media screen and (min-width: 1000px) {
  .newsletter {
    padding-top: var(--spacing-2);
  }
}

.newsletter__inner {
  max-width: var(--max-width-med);
  margin: 0 auto;
  padding: 0 var(--side);
  position: relative;
}

#sib-container-newsletter {
  background-color: unset;
  padding: 0;
}

.sib-form {
  padding: 0;
  margin: var(--spacing-1) 0;
  font-family: var(--font-copy) !important;
}

.sib-form-block {
  padding: 0;
}

#sib-form-newsletter {
  margin: 0;
}

.newsletter .sib-form .entry__field {
  border: none;
  border-radius: 0;
  margin: 16px 0 0;
  padding: 6px 8px;
  position: relative;
  background-color: var(--grey);
}

@media screen and (min-width: 700px) {
  .newsletter .sib-form .entry__field {
    height: 54px;
  }
}

.sib-form .input::placeholder {
  color: var(--dark-grey-40) !important;
  line-height: var(--line-height);
  font-size: 16px;
  font-family: var(--font-copy) !important;
}

.sib-form .input {
  color: var(--dark-grey) !important;
  line-height: var(--line-height);
  font-size: 16px;
  font-family: var(--font-copy) !important;
}

.sib-form .entry__error {
  margin: 0;
}

@media screen and (min-width: 700px) {
  .newsletter__name {
    display: grid;
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    gap: 16px;
  }
}

.sib-form-block__button {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: var(--font-copy);
  background-color: var(--brown-light);
  padding: 1em !important;
  height: 54px;
  transition: background-color var(--link-transition);
}

.sib-form-block__button:hover {
  background-color: var(--orange);
}

.newsletter__button-text {
  position: relative;
  z-index: 1;
  pointer-events: none;
  color: var(--white);
  font-weight: bold;
  line-height: var(--line-height);
}

.newsletter__required {
  font-size: 14px;
  color: var(--dark-grey);
  display: inline-block;
  margin: 8px 0;
}

.sib-form__declaration {
  font-size: 14px;
  color: var(--dark-grey);
  margin: 16px 0;
  padding: 0;
  max-width: 600px;
  display: block;
}

.sib-form__declaration a {
  color: inherit;
}

#error-message-newsletter.sib-form-message-panel {
  border-color: #ff4949;
  color: #ff4949;
  margin-top: 16px;
}

#success-message-newsletter.sib-form-message-panel {
  border-color: rgb(30 195 30);
  color: rgb(30 195 30);
  margin-top: 16px;
}

.sib-sms-tooltip {
  position: absolute;
  top: 16px;
  right: 0;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sib-sms-tooltip__icon {
  color: var(--dark-grey);
  font-family: var(--font-copy);
  font-size: unset;
}

.newsletter__bookingnumber-field,
.newsletter__traveltimes-field {
  display: none;
}

.newsletter__consens {
  font-size: 12px;
  color: var(--dark-grey);
  opacity: 0.7;
}

.newsletter__button-wrapper {
  margin-top: 16px;
}

@media screen and (min-width: 700px) {
  .newsletter__fields-wrapper {
    display: flex;
  }

  .newsletter__button-wrapper {
    width: 40%;
    max-width: 284px;
    flex-shrink: 0;
  }

  .newsletter__email {
    width: 100%;
    height: 100%;
  }

  .newsletter .entry__error {
    margin-top: 4px !important;
  }
}
