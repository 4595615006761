.contact-cta__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--brown);
}

.contact-cta__text {
  font-size: var(--paragraph-size);
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  letter-spacing: 0.6px;
  line-height: var(--line-height);
}

.contact-cta__text p {
  margin-bottom: 1em;
}

.contact-cta__button {
  width: 100%;
  max-width: 324px;
  margin-top: var(--spacing-1);
}

.contact-cta__headline {
  font-weight: bold;
  font-size: var(--paragraph-size);
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  letter-spacing: 0.6px;
  line-height: var(--line-height);
}

.contact-cta__link {
  color: var(--white);
  background-color: var(--brown-light);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.contact-cta__link:hover,
.contact-cta__link:focus {
  background-color: var(--orange);
}
