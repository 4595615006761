.read-more-button {
  all: unset;
  color: inherit;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.read-more-button:hover {
  opacity: 0.8;
}
