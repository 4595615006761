.facts-house {
  color: var(--brown);
  background-color: var(--grey);
  padding: var(--spacing-2) 0;
}

@media screen and (min-width: 1000px) {
  .facts-house {
    padding: 0;
  }
}

.facts-house__inner {
  margin: 0 auto;
}

@media screen and (min-width: 1000px) {
  .facts-house__inner {
    max-width: var(--max-width);
    padding: 0 var(--side);
    display: flex;
  }
}

.facts-house__fact-headline {
  font-family: var(--font-headline);
  font-size: 30px;
  text-decoration: underline;
  margin: 0 0 0.25em;
}

.facts-house__fact-text {
  font-size: var(--paragraph-size);
  text-align: center;
  color: var(--dark-grey);
}

.facts-house__fact-icon {
  width: 140px;
}

@media screen and (min-width: 1000px) {
  .facts-house__fact {
    width: 25%;
  }
}

.facts-house__card-inner {
  padding: var(--spacing-1) 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1000px) {
  .facts-house__card-inner {
    padding: var(--spacing-4) 15px;
    height: 100%;
  }
}

.facts-house__headline-wrapper {
  padding: 0 var(--side);
}
