.intro-text__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-4) var(--side) var(--spacing-5);
  position: relative;
}

.intro-text__inner--house {
  padding: var(--spacing-6) var(--side) var(--spacing-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-text__inner--contact {
  padding: var(--spacing-2) var(--side);
}

.intro-text__inner::after {
  content: "";
  background-image: url("../../../images/compass-small.svg");
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: -60px;
  left: var(--side);
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  object-position: bottom;
  z-index: 2;
}

.intro-text__inner--contact::after,
.intro-text__inner--house::after {
  display: none;
}

@media screen and (min-width: 1000px) {
  .intro-text__inner::after {
    width: 200px;
    height: 200px;
    bottom: -100px;
    left: 100px;
  }
}

.intro-text__text {
  font-size: var(--paragraph-size);
  margin: 1em auto;
  max-width: 1000px;
  text-align: center;
  letter-spacing: 0.6px;
}

.intro-text__text p {
  margin-bottom: 1.8em;
}

.intro-text__text p:last-child {
  margin-bottom: 0;
}

.intro-text__headline-wrapper--house {
  display: flex;
  align-items: center;
  justify-content: center;
}
