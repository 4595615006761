.travel-facts {
  padding: var(--spacing-2) 0 var(--spacing-1);
  background-color: var(--grey);
  position: relative;
  color: var(--brown);
}

@media screen and (min-width: 768px) {
  .travel-facts {
    padding: var(--spacing-2) 0;
  }
}

.travel-facts__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
}

@media screen and (min-width: 768px) {
  .travel-facts__list {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    column-gap: 2em;
  }
}

@media screen and (min-width: 1000px) {
  .travel-facts__list {
    column-gap: 4em;
  }
}

.travel-facts__item-title {
  font-family: var(--font-copy);
  font-weight: 700;
  text-decoration: underline;
  grid-row-start: 1;
  font-size: 22px;
  margin-bottom: 0.1em;
}

@media screen and (min-width: 768px) {
  .travel-facts__item-title {
    font-size: 24px;
  }
}

.travel-facts__item-detail {
  margin: 0 0 var(--spacing-1);
  color: var(--orange);
  grid-row-start: 2;
  font-size: 17px;
}

@media screen and (min-width: 768px) {
  .travel-facts__item-detail {
    font-size: var(--paragraph-size);
  }
}

.travel-facts__item-title:nth-of-type(1),
.travel-facts__item-title:nth-of-type(1) {
  grid-column-start: 1;
}
.travel-facts__item-title:nth-of-type(2),
.travel-facts__item-title:nth-of-type(2) {
  grid-column-start: 2;
}
.travel-facts__item-title:nth-of-type(3),
.travel-facts__item-title:nth-of-type(3) {
  grid-column-start: 3;
}
.travel-facts__item-title:nth-of-type(4),
.travel-facts__item-detail:nth-of-type(4) {
  grid-column-start: 4;
}
