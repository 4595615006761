.icon-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.icon-nav__item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 28px;
  overflow: hidden;
}

.icon-nav__item--fb {
  justify-content: flex-end;
}

.icon-nav__item:first-child {
  margin-left: 0;
}

.icon-nav__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.icon-nav__icon-bg {
  fill: var(--brown-light);
  transition: fill var(--link-transition);
}

.icon-nav__icon-bg--inverted {
  fill: var(--grey);
}

.icon-nav__icon:hover .icon-nav__icon-bg {
  fill: var(--orange);
}

.icon-nav__icon-inner {
  fill: white;
}

.icon-nav__icon-inner--inverted {
  fill: var(--brown);
}
