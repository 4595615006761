.headline {
  font-family: var(--font-headline);
  text-align: center;
  font-size: 38px;
  line-height: 42px;
  color: var(--brown);
  position: relative;
}

@media screen and (min-width: 1000px) {
  .headline {
    font-size: 55px;
    line-height: 60px;
  }
}

.headline--reise {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .headline--reise {
    text-align: left;
    max-width: unset;
    margin: 0;
  }
}

.headline--house {
  max-width: 400px;
}

@media screen and (min-width: 768px) {
  .headline--house {
    max-width: unset;
  }
}

.headline__first-word-wrapper--house {
  display: inline-block;
  position: relative;
}

.headline__first-word-wrapper--house::before {
  content: "";
  background-image: url("../../images/exklusiv.png");
  background-size: contain;
  background-position: bottom;
  width: 80px;
  height: 110px;
  position: absolute;
  left: -0.85em;
  bottom: 0.4em;
  background-repeat: no-repeat;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .headline__first-word-wrapper--house::before {
    width: 110px;
    height: 110px;
    left: -1.2em;
    bottom: 0.15em;
  }
}

@media screen and (min-width: 1000px) {
  .headline__first-word-wrapper--house::before {
    width: 160px;
    height: 160px;
  }
}
