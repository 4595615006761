.related-travels {
  position: relative;
}

.related-travels__inner {
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-2) var(--side-small);
  position: relative;
}

@media screen and (min-width: 1000px) {
  .related-travels__inner {
    max-width: var(--max-width);
    padding-top: var(--spacing-2);
  }
}

.related-travels__cards {
  display: none;
}

.related-travels__slider {
  display: block;
}

@media screen and (min-width: 1000px) {
  .related-travels__slider {
    display: none;
  }

  .related-travels__cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(1px, 1fr));
    column-gap: 30px;
    margin: var(--spacing-3) 0 var(--spacing-2);
  }
}

.related-travels__kicker {
  font-size: 38px;
  font-family: var(--font-headline);
  color: var(--brown);
  line-height: 1.2em;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

@media screen and (min-width: 1000px) {
  .related-travels__kicker {
    font-size: 55px;
    text-align: left;
    margin: 0;
    max-width: unset;
  }
}
