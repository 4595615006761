.breadcrumb__list {
  padding: 0;
  display: flex;
  list-style: none;
  font-size: 14px;
  margin: var(--spacing-3) 0 var(--spacing-2);
  color: var(--brown);
  flex-wrap: wrap;
}

.breadcrumb__item:not(:last-child) {
  padding-right: 1.5em;
  position: relative;
}

.breadcrumb__item:not(:last-child)::after {
  content: "/";
  position: absolute;
  right: 0.5em;
  top: 0;
}

.breadcrumb__link {
  text-decoration: none;
}

.breadcrumb__link:hover {
  color: var(--orange);
}
