.welle-feuer {
  height: 35.7vw;
  max-height: 225px;
  background-image: url("../../images/welle-4.svg");
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center;
  position: relative;
  top: -1px;
}

@media screen and (min-width: 1000px) {
  .welle-feuer {
    background-size: 80%;
  }
}

.welle-feuer__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.welle-feuer__img {
  width: 30vw;
  height: 30vw;
  max-width: 150px;
  max-height: 150px;
  margin-left: 55%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .welle-feuer__img {
    margin-left: 64%;
  }
}

@media screen and (min-width: 1000px) {
  .welle-feuer__img {
    max-width: 180px;
    max-height: 180px;
  }
}
