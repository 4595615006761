.related__wrapper {
  --card-shift: 20px;

  width: 100%;
  margin-top: calc(2 * var(--card-shift));
}

.related {
  padding: 0 16px 26px 16px;
  text-decoration: none;
  transition: box-shadow var(--link-transition);
  display: block;
  background-color: var(--grey);
  max-width: 350px;
  margin: calc(2 * var(--card-shift)) auto var(--spacing-3);
}

@media screen and (min-width: 1000px) {
  .related {
    margin-bottom: var(--spacing-4);
    padding: 0 20px 30px 20px;
    max-width: unset;
  }
}

.related__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.related__headline {
  font-size: 18px;
  margin: 0 0 1.5em;
  font-weight: 700;
}

.related__headline-kicker {
  margin: 0 0 0.5em;
  font-weight: 700;
}

.related__text {
  font-size: 16px;
  margin-bottom: 2em;
}

.related__cover-wrapper {
  position: relative;
  transform: translateY(calc(0px - var(--card-shift)));
  padding-top: 59%;
  height: 0;
  display: flex;
  justify-content: center;
}

.related__cover {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: calc(100% + var(--card-shift));
  object-fit: cover;
}

.related__label {
  min-width: 60%;
  background-color: #e18700e6;
  text-align: center;
  padding: 8px;
  position: absolute;
  color: var(--white);
  top: calc(0px - var(--card-shift));
  display: inline-block;
}

.related__facts {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
}

.related__fact {
  font-family: var(--font-copy);
  font-weight: 700;
}

.related__fact--addition {
  font-family: var(--font-copy);
  font-weight: 400;
  margin-left: 2px;
}

.related__button {
  color: var(--white);
  background-color: var(--brown-light);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.related__button:hover,
.related__button:focus {
  background-color: var(--orange);
}

@media screen and (min-width: 1000px) {
  .related:hover {
    box-shadow: 0 0 15px #59453680;
  }
}

.related:hover .related__button {
  background-color: var(--orange);
}
