.image-slider {
  position: relative;
}

.image-slider__cell {
  position: relative;
  height: 0;
  padding-top: 100%;
  width: 90vw;
}

.image-slider__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

@media screen and (min-width: 768px) {
  .image-slider {
    display: none;
  }
}
