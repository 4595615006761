.eldorado-facts {
  color: var(--brown);
}

@media screen and (min-width: 1340px) {
  .eldorado-facts {
    margin-top: var(--spacing-6);
    background-color: var(--grey);
  }
}

.eldorado-facts__inner {
  margin: 0 auto;
  padding: var(--spacing-4) 0 0;
}

.eldorado-facts__inner--house {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .eldorado-facts__inner {
    padding: var(--spacing-6) 0;
  }
}

@media screen and (min-width: 1340px) {
  .eldorado-facts__inner {
    padding: var(--spacing-4) var(--side) var(--spacing-2);
    max-width: var(--max-width);
  }

  .eldorado-facts__inner--house {
    padding: 0 var(--side);
  }
}

.eldorado-facts__slider {
  margin: var(--spacing-2) 0;
}

.eldorado-facts__slider--house {
  margin: 0;
}

@media screen and (min-width: 1340px) {
  .eldorado-facts__slider {
    margin: var(--spacing-1) 0;
  }

  .eldorado-facts__slider--house {
    margin: 0;
  }
}

.eldorado-facts__fact-headline {
  font-family: var(--font-headline);
  font-size: 30px;
  margin: 1em 0 0.5em;
}

.eldorado-facts__fact-headline--house {
  text-decoration: underline;
  margin: 0 0 0.5em;
}

.eldorado-facts__fact-text {
  font-size: var(--paragraph-size);
}

.eldorado-facts__fact-icon {
  width: 110px;
  object-fit: contain;
  height: 110px;
}

.eldorado-facts__fact {
  text-align: center;
  background-color: var(--grey);
  width: 100vw;
}

@media screen and (min-width: 1340px) {
  .eldorado-facts__fact {
    margin-right: 30px;
    width: calc((var(--max-width) - var(--side) - var(--side) - 60px) / 3);
  }
}

.eldorado-facts__card-inner {
  padding: 64px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.eldorado-facts__card-inner--house {
  padding: var(--spacing-4) 80px;
}

@media screen and (min-width: 1340px) {
  .eldorado-facts__card-inner {
    padding: 40px 24px;
    height: 100%;
  }

  .eldorado-facts__card-inner--house {
    padding: var(--spacing-4) 24px;
  }
}

.eldorado-facts__headline-wrapper {
  padding: 0 var(--side);
}

@media screen and (min-width: 600px) {
  .eldorado-facts__slider .flickity-prev-next-button.previous {
    left: calc((100vw - 580px) / 2);
  }

  .eldorado-facts__slider .flickity-prev-next-button.next {
    right: calc((100vw - 580px) / 2);
  }
}

@media screen and (min-width: 1340px) {
  .eldorado-facts__slider .flickity-prev-next-button.previous,
  .eldorado-facts__slider .flickity-prev-next-button.next,
  .eldorado-facts__slider .flickity-page-dots {
    display: none;
  }

  .eldorado-facts__slider .flickity-enabled.is-draggable .flickity-viewport {
    cursor: default !important;
  }
}
