.block-type-gallery figure {
  margin: 0;
}

.block-type-gallery ul {
  margin: var(--spacing-2) 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, minmax(1px, 1fr));
  align-items: flex-start;
}

.block-type-gallery img {
  width: 100%;
}
