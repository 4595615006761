.travel-accordion {
  padding: var(--spacing-2) 0;
  background-color: var(--grey);
}

.travel-accordion__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
}

.travel-accordion__item {
  border-bottom: 1px solid var(--brown);
  padding: 0 8px 0 0;
}

.travel-accordion__item:first-child {
  border-top: 1px solid var(--brown);
}

.travel-accordion__item-title {
  font-size: var(--paragraph-size);
  display: block;
  font-family: var(--font-copy);
  font-weight: 700;
  margin: 0;
  color: var(--brown);
  transition: color var(--link-transition);
}

.travel-accordion__item-title-wrapper {
  cursor: pointer;
  transition: color 100ms ease;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 0;
}

.travel-accordion__item-title-wrapper::-webkit-details-marker {
  display: none;
}

/* Hide arrow icon in IE browsers */
.travel-accordion__item-title-wrapper::-ms-expand {
  display: none;
}

.travel-accordion__item-title-wrapper:focus {
  outline: none;
}

.travel-accordion__item-title-wrapper::after {
  content: ">";
  font-family: var(--font-headline);
  font-size: 23px;
  background-repeat: no-repeat;
  width: 15px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  color: var(--orange);
  position: absolute;
  right: 0;
  top: 11px;

  transition: transform var(--link-transition);
}

.travel-accordion__item[open] .travel-accordion__item-title-wrapper::after {
  transform: rotate(90deg);
}

.travel-accordion__item-body {
  margin: 0 var(--spacing-1) var(--spacing-2) 0;
  max-width: 1000px;
}

@media screen and (min-width: 768px) {
  .travel-accordion__item-body {
    margin: 0 var(--spacing-4) var(--spacing-2) 0;
  }
}

.travel-accordion__item[open] .travel-accordion__item-body {
  animation: travel-accordion__fadein 400ms ease;
}

@keyframes travel-accordion__fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.travel-accordion__item-body p {
  margin-bottom: 1em;
}

.travel-accordion__item-body h3,
.travel-accordion__item-body h1,
.travel-accordion__item-body h2,
.travel-accordion__item-body h4 {
  font-size: inherit;
  text-decoration: underline;
  font-family: var(--font-copy);
  font-weight: 700;
  margin-top: 2em;
}

.travel-accordion__date-price-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.travel-accordion__date-price-list-item {
  display: grid;
  column-gap: 2em;
  grid-template-columns: 185px 65px;
  padding: 0.5em 0;
}

@media screen and (min-width: 768px) {
  .travel-accordion__date-price-list-item {
    column-gap: 3em;
    grid-template-columns: 185px 65px max-content;
  }
}

.travel-accordion__date-price-list-item:last-child {
  border-bottom: none;
}

.travel-accordion__date-price-list-item-cta {
  grid-column: 1/-1;
}

@media screen and (min-width: 768px) {
  .travel-accordion__date-price-list-item-cta {
    grid-column: span 1;
  }
}

.travel-accordion__date-price-list-item-date {
  white-space: nowrap;
}

.travel-accordion__date-price-list-link {
  font-family: var(--font-copy);
  font-weight: 700;
  transition: color var(--link-transition);
}

.travel-accordion__date-price-list-link:hover {
  color: var(--orange);
}

.travel-accordion__text-gallery {
  display: flex;
}

.travel-accordion__text-gallery img {
  width: 45%;
}
