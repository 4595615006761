.price-per-night {
  background-color: var(--grey);
  position: relative;
}

.price-per-night__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-2) var(--side) 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .price-per-night__inner {
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
    position: relative;
  }
}

.price-per-night__price {
  color: var(--brown);
  font-size: var(--paragraph-size);
  border-bottom: 6px solid var(--orange);
  display: inline-block;
  line-height: var(--line-height);
  margin-bottom: var(--spacing-3);
}

@media screen and (min-width: 768px) {
  .price-per-night__price {
    margin-bottom: 0;
  }
}

.price-per-night__amount {
  font-size: 50px;
  line-height: 1.1em;
  display: inline-block;
  margin: 0 0.05em;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .price-per-night__amount {
    font-size: 70px;
  }
}

.price-per-night__link-wrapper {
  max-width: 324px;
  width: 100%;
}

.price-per-night__link {
  color: var(--white);
  background-color: var(--brown-light);
  padding: 1em;
  font-family: var(--font-copy);
  font-weight: 700;
  letter-spacing: 0.54px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  box-shadow: unset;
  transition: background-color var(--link-transition);
}

.price-per-night__link:hover,
.price-per-night__link:focus {
  background-color: var(--orange);
}
