.text {
  margin: var(--spacing-1) 0;
}

.text__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
}

.text__text {
  font-size: var(--paragraph-size);
  max-width: 1000px;
  letter-spacing: 0.6px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .text__text {
    text-align: left;
  }
}

.text__text p {
  margin-bottom: 1.5em;
}

.text__text h2 {
  margin-bottom: 1em;
}

.text__text p,
.text__text h1,
.text__text h2,
.text__text h3,
.text__text h4,
.text__text h5 {
  font-size: inherit;
}
