.header-image-slider {
  background-color: rgb(155, 177, 196);
  height: 70vw;
  max-height: 680px;
  display: grid;
}

@media screen and (min-width: 768px) {
  .header-image-slider {
    height: 68vh;
    min-height: 450px;
  }
}

.header-image-slider__inner {
  width: 100%;
  max-width: var(--max-width);
  margin: var(--spacing-2) auto 0;
  padding: 0 var(--side);
  grid-column-start: 1;
  grid-row-start: 1;
  z-index: 3;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header-image-slider__inner {
    margin-top: var(--spacing-3);
  }
}

.header-image-slider__gallery {
  grid-column-start: 1;
  grid-row-start: 1;
  position: relative;
  overflow: hidden;
}

.header-image-slider__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: scale(1);
  transition: opacity 1.5s ease, transform 8s ease;
}

.header-image-slider__image--active {
  opacity: 1;
  transform: scale(1.1);
}

.header-image-slider__image--first {
  z-index: 2;
}

.header-image-slider__cactus {
  width: 12vw;
  height: 12vw;
  max-width: 100px;
  max-height: 100px;
  position: absolute;
  bottom: -1px;
  right: 12vw;
}

@media screen and (min-width: 833px) {
  .header-image-slider__cactus {
    right: 100px;
  }
}

.header-image-slider__cactus-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  fill: white;
}

.header-image-slider__world {
  width: 25vw;
  max-width: 285px;
}
