.partner__inner {
  margin: 0 auto;
  padding: var(--spacing-4) var(--side) var(--spacing-2);
}

@media screen and (min-width: 1340px) {
  .partner__inner {
    max-width: var(--max-width);
  }
}

.partner__list {
  margin: var(--spacing-4) 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(1px, 1fr));
  gap: 40px;
}

@media screen and (min-width: 700px) {
  .partner__list {
    grid-template-columns: repeat(3, minmax(1px, 1fr));
    gap: 60px;
  }
}

@media screen and (min-width: 1000px) {
  .partner__list {
    grid-template-columns: repeat(4, minmax(1px, 1fr));
  }
}

.partner__list-item {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
}

.partner__list-link {
  display: block;
  height: 100%;
  max-height: 80px;
  width: 100%;
  max-width: 180px;
}

@media screen and (min-width: 1000px) {
  .partner__list-link {
    max-height: 95px;
  }
}

.partner__list-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
