:root {
  --max-width: 1200px;
  --max-width-med: 850px;
  --max-width-small: 700px;
  --line-height: 1.45;
  --brown: #5d0f0f;
  --brown-light: #771313;
  --white: white;
  --grey: #f0eeed;
  --grey-60: rgba(240, 238, 237, 0.6);
  --grey-80: rgba(240, 238, 237, 0.8);
  --grey-95: rgba(240, 238, 237, 0.95);
  --grey-border: #dcd9d5;
  --dark-grey: rgb(89, 69, 54);
  --dark-grey-70: rgba(89, 69, 54, 0.7);
  --dark-grey-40: rgba(89, 69, 54, 0.4);
  --orange: rgb(225, 141, 0);
  --orange-85: rgba(225, 141, 0, 0.85);
  --orange-60: rgba(225, 141, 0, 0.6);
  --orange-40: rgba(225, 141, 0, 0.4);
  --spacing-1: 16px;
  --spacing-2: 32px;
  --spacing-3: 48px;
  --spacing-4: 64px;
  --spacing-5: 80px;
  --spacing-6: 96px;
  --spacing-7: 160px;
  --spacing-svg: 130px;
  --side: 30px;
  --side-small: 15px;
  --link-transition: 200ms ease;
  --paragraph-size: 17px;
}

@media screen and (min-width: 500px) {
  :root {
    --spacing-svg: 150px;
  }
}

@media screen and (min-width: 700px) {
  :root {
    --side-small: 30px;
    --spacing-svg: 250px;
  }
}

@media screen and (min-width: 1000px) {
  :root {
    --paragraph-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --paragraph-size: 20px;
    --max-width-med: 960px;
  }
}
