.contact-header__inner {
  max-width: 940px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 10vw 1fr 10vw;
  grid-template-rows: minmax(auto, 300px) minmax(auto, 100px) auto;
}

@media screen and (min-width: 550px) {
  .contact-header__inner {
    grid-template-columns: 20vw 1fr 20vw;
  }
}

@media screen and (min-width: 1000px) {
  .contact-header__inner {
    max-width: var(--max-width);
    padding: var(--spacing-4) var(--side) 0;
    grid-template-columns: 2fr 1fr 3fr;
    grid-template-rows: var(--spacing-4) 1fr var(--spacing-6);
  }
}

.contact-header__text {
  font-size: var(--paragraph-size);
  max-width: 1000px;
  letter-spacing: 0.6px;
}

@media screen and (min-width: 1000px) {
  .contact-header__text {
    margin: 1em 0 2em;
  }
}

.contact-header__text *:last-child {
  margin: 0;
}

.contact-header__headline-mobile {
  font-family: var(--font-headline);
  font-size: 38px;
  line-height: 1.1;
  color: var(--brown);
  text-align: center;
  padding: var(--spacing-4) var(--side) var(--spacing-2);
}

@media screen and (min-width: 1000px) {
  .contact-header__headline-mobile {
    display: none;
  }
}

.contact-header__headline {
  font-family: var(--font-headline);
  font-size: 55px;
  line-height: 1.1;
  color: var(--brown);
  display: none;
}

@media screen and (min-width: 1000px) {
  .contact-header__headline {
    display: block;
  }
}

.contact-header__text p {
  margin-bottom: 1em;
}

.contact-header__text ul {
  margin: 0;
  padding: 0 0 0 1.2em;
}

.contact-header__text li {
  margin: 0;
  line-height: 1.8;
}

.contact-header__text li p {
  margin: 0;
}

.contact-header__info-inner {
  max-width: var(--max-width-small);
  margin: 0 auto;
}

.contact-header__info {
  grid-column: 2 / -1;
  grid-row: 2 / 4;
  background-color: var(--grey-95);
  padding: var(--spacing-2) var(--side);
  position: relative;
}

@media screen and (min-width: 1000px) {
  .contact-header__info {
    grid-column: 1 / -2;
    grid-row: 1 / 3;
    padding: 64px;
  }

  .contact-header__info-inner {
    max-width: unset;
    margin: 0;
  }
}

.contact-header__image {
  grid-column: 1 / -2;
  grid-row: 1 / 3;
  display: flex;
}

@media screen and (min-width: 1000px) {
  .contact-header__image {
    grid-column: 2 / -1;
    grid-row: 2 / 4;
  }
}

.contact-header__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
