.image-grid {
  margin-bottom: var(--spacing-4);
}

@media screen and (min-width: 1000px) {
  .image-grid {
    margin-bottom: var(--spacing-4);
  }
}

.image-grid__inner {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--side);
  display: grid;
  grid-template-columns: repeat(2, minmax(1px, 1fr));
  gap: 20px;
}

@media screen and (min-width: 1000px) {
  .image-grid__inner {
    grid-template-columns: repeat(4, minmax(1px, 1fr));
  }
}

.image-grid__image {
  display: flex;
}

.image-grid__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
