.go-top {
  bottom: var(--spacing-2);
  position: sticky;
  max-width: 1500px;
  padding: 0 var(--side);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  pointer-events: none;
}

.go-top__button {
  background-color: rgba(255, 255, 255, 0.2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("../../../assets/images/go-up-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  box-shadow: unset;
  border: unset;
  pointer-events: initial;
}
