.button-svg {
  fill: var(--brown);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.button-svg:hover {
  fill: var(--orange);
}
